import SSPContentExt from 'sspcontentext';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Context from './context';
import {
    INITIAL_STATE,
    reducer,
    SET_CONTENT_ACTION,
    SET_SSP_ACTION,
} from './store';
import checkForUpdates from './checkForUpdates.ts';
// import registerSW from '../serviceWorkerRegistration.ts';
import { customFetch } from '../customFetch.ts';
import registerSW from '../serviceWorkerRegistration.ts';
import getCookie from '../getCookie.ts';

let initialPageLoad = false;

let progress: HTMLDivElement | null = null;

function SspContextProvider({
    children,
    prepareOfflineUse,
    // setupLink,
    showLabel,
}: {
    children: React.ReactNode;
    prepareOfflineUse: boolean;
    // setupLink: string; // todo
    showLabel?: boolean;
}): React.ReactElement {
    const params = new URLSearchParams(window.location.search);
    const setup = getCookie('setup');
    const audi = setup?.brand === 'audi';
    const device = setup?.device;
    const url = audi
        ? (import.meta.env.VITE_REACT_APP_SSP_URL_AUDI as string)
        : (import.meta.env.VITE_REACT_APP_SSP_URL_VW as string);
    const user = audi
        ? (import.meta.env.VITE_REACT_APP_SSP_USER_AUDI as string)
        : (import.meta.env.VITE_REACT_APP_SSP_USER_VW as string);
    const password = audi
        ? (import.meta.env.VITE_REACT_APP_SSP_PASSWORD_AUDI as string)
        : (import.meta.env.VITE_REACT_APP_SSP_PASSWORD_VW as string);
    const credentials = btoa(`${user}:${password}`);

    const devMode = params.get('dev') === 'true';

    const fetchOptions = {
        method: 'GET',
        headers: {
            Authorization: `Basic ${credentials}`,
        },
    };

    if (!progress && prepareOfflineUse) {
        progress = document.createElement('div');
        progress.setAttribute('class', 'caching-progress');
        document.getElementsByTagName('body')[0].append(progress);
    }

    /**
     * state for global ssp context
     * provider
     */
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [error, setError] = useState<{
        msg: string;
        status?: number | null;
    } | null>(null);

    async function cache(fetchURL: string, type?: string): Promise<string> {
        const cacheName = 'mpa-webapp-assets';

        const check = await caches.open(cacheName).then(cache =>
            cache.match(fetchURL).then(
                res => res !== undefined,
                () => false
            )
        );

        if (devMode || check) {
            return new Promise<string>(resolve => {
                resolve(fetchURL);
            });
        }

        return new Promise<any>(resolve => {
            customFetch(fetchURL, credentials, type)
                .then(async fetchResponse => {
                    if (
                        !(
                            fetchResponse.status !== 204 &&
                            fetchResponse.status !== 206
                        )
                    ) {
                        console.warn(
                            `failed to cache ${fetchURL} with status ${fetchResponse.status}`
                        );
                    } else {
                        caches
                            .open(cacheName)
                            .then(cache => cache.put(fetchURL, fetchResponse))
                            .then(() => resolve(fetchURL));
                    }
                })
                .catch((error: any) => {
                    console.log(
                        `SSPContextProvider - failed to fetch ${fetchURL}`,
                        error
                    );
                });
        });
    }

    function initWithJsonURL(
        ext: SSPContentExt,
        jsonURL: string
    ): Promise<any> {
        return customFetch(jsonURL, credentials)
            .then(response => {
                if (response.status === 200) {
                    return caches
                        .open('did-webapp-precache')
                        .then(cache => cache.put(jsonURL, response.clone()))
                        .then(() =>
                            response.json().then(
                                (json: any) => json,
                                (reason: any) =>
                                    setError({ msg: `${reason} ${jsonURL}` })
                            )
                        );
                }
                setError({ msg: jsonURL, status: response.status });
            })
            .then(async (json: Array<object> | undefined) => {
                if (json) {
                    ext.initWithModels(json);

                    let cached = 0;
                    if (prepareOfflineUse)
                        await Promise.all(
                            json.map(async (asset: any) => {
                                if (
                                    (asset.ContentType === 'assetImage' ||
                                        asset.ContentType === 'assetDocument' ||
                                        asset.ContentType === 'assetUnknown') &&
                                    asset.AssetDataSet !== null
                                ) {
                                    const fetchURL = ext.getAssetUrl(
                                        asset.AssetDataSet.AssetFileProperties
                                            .FileHash
                                    );
                                    await cache(
                                        fetchURL,
                                        asset.AssetDataSet.AssetFileProperties
                                            .FileType === 'svg'
                                            ? 'image/svg+xml'
                                            : undefined
                                    );
                                }
                                if (asset.ContentType === 'assetVideo') {
                                    // if video caches additional thumbnail image
                                    const fetchURL = ext.getAssetUrl(
                                        asset.AssetDataSet.PosterFileProperties
                                            .FileHash
                                    );
                                    await cache(fetchURL);
                                    if (
                                        asset.AssetDataSet.AssetFileProperties
                                            .Size <= 300000000
                                    ) {
                                        const videoFetchURL = ext.getAssetUrl(
                                            asset.AssetDataSet
                                                .AssetFileProperties.FileHash
                                        );
                                        await cache(videoFetchURL);
                                    }
                                }

                                cached += 1;
                                if (progress) {
                                    progress.textContent =
                                        cached === json.length
                                            ? ''
                                            : `${cached}/${json.length}`;
                                }
                                // register service worker once everything is cached
                                if (
                                    cached === json.length &&
                                    window.navigator.onLine
                                ) {
                                    registerSW(password, 'mpa', [
                                        '/mpa/index.html',
                                    ]);
                                }
                            })
                        );
                }
            })
            .catch((err: any) => {
                Promise.reject(err);
            });
    }

    /**
     * inits ssp to load data
     * from the server
     *
     * @param lang
     * @param device
     */
    const initSSP = async () => {
        try {
            /**
             * create class that handles the SSPContentExt
             */
            const ext = SSPContentExt.instance;
            if (device && !devMode) console.log('check for updates');
            checkForUpdates(url, device, fetchOptions, showLabel);

            const sspWeb = {
                ext,
                init: async () => {
                    try {
                        ext.configure(url);
                        await initWithJsonURL(
                            ext,
                            `${url}/module/content/api/v1/depot/terminals/${device}/allcontents.json`
                        );
                    } catch (e) {
                        console.error(
                            `Couldn't initialize SSP. Error: ${error}`
                        );
                    }
                },
            };

            await sspWeb.init();

            return sspWeb;
        } catch (error) {
            console.error("Couldn't initialize SSP", error);
            throw new Error(`${error}`);
        }
    };

    /**
     * retrieves the initial content from ssp
     */
    const handleInitialPageLoad = useCallback(async () => {
        try {
            /**
             * set the language for ssp provider by given
             * url param
             */
            let sspExt = await initSSP();

            /**
             * load data from ssp and pass it to
             * the ssp provider store
             */

            if (sspExt) {
                if (
                    sspExt.ext.projectModel &&
                    sspExt.ext.projectModel.asModel.contentTreeChildren.length >
                        0
                ) {
                    const priceSheet =
                        sspExt.ext.projectModel.asModel.contentTreeChildren[0];
                    dispatch({ type: SET_CONTENT_ACTION, value: priceSheet });
                    dispatch({ type: SET_SSP_ACTION, value: sspExt.ext });
                }
            } else {
                const retrySSPConnectionInterval = setInterval(async () => {
                    sspExt = await initSSP();

                    if (!sspExt) {
                        clearInterval(retrySSPConnectionInterval);
                    } else {
                        dispatch({ type: SET_SSP_ACTION, value: sspExt.ext });
                    }
                }, 1000);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (!initialPageLoad) {
            initialPageLoad = true;
            handleInitialPageLoad();
        }
    }, [handleInitialPageLoad]);

    const getContextValue = useCallback(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch]
    );

    return (
        <Context.Provider value={getContextValue()}>
            {children}
        </Context.Provider>
    );
}

export default SspContextProvider;
