import { useEffect, useState } from 'react';
import './styles.scss';
import i18next from 'i18next';
import getCookie from '../../utils/getCookie.ts';
import KeyboardWrapper from '../Keyboard';

var errorTimer: number | undefined;
export default function BackToSetup() {
    const [clicks, setClicks] = useState<number>(0);
    const [lastClick, setLastClick] = useState<number | null>(null);
    const [iFrame, setIFrame] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [pw, setPW] = useState<string>('');
    const [showPW, setShowPW] = useState<boolean>(false);

    const newUrl = new URL(window.location.href.replace('mpa/', ''));
    const apk = newUrl.searchParams.get('apk') === 'true';
    newUrl.searchParams.set('redirect', 'false');

    function backFunction() {
        if (lastClick === null || Date.now() - lastClick > 1000) {
            setLastClick(Date.now());
            setClicks(1);
        } else {
            if (clicks >= 12) {
                setIFrame(true);
            }
            setLastClick(Date.now());
            setClicks(prev => prev + 1);
        }
    }

    window.addEventListener('message', e => {
        if (e.data === 'close') {
            setIFrame(false);
            setClicks(0);
        }
        if (e.data === 'redirect') {
            newUrl.searchParams.set('change-device', 'true');
            window.location.replace(newUrl);
        }
        if (e.data === 'locked') {
            newUrl.searchParams.set('locked', 'false');
            window.location.replace(newUrl);
        }
        if (e.data === 'error') {
            setError(true);
        }
        if (e.data === 'loaded') {
            window.clearTimeout(errorTimer);
            errorTimer = undefined;
        }
    });

    useEffect(() => {
        if (iFrame && !error) {
            errorTimer = window.setTimeout(() => setError(true), 5000);
        }
    }, [iFrame, error]);

    return (
        <div
            className='back-to-setup'
            onClick={backFunction}
            style={{
                width: 100 + 'vw',
            }}
        >
            {!error &&
                new URLSearchParams(window.location.search).get('dev') ===
                    'true' && <h1>{clicks}</h1>}
            {iFrame && !error && <iframe src={newUrl + '&in-frame=true'} />}
            {error && iFrame && (
                <div className='iframe-replacement-offline'>
                    <button
                        onClick={() => {
                            setIFrame(false);
                            setError(false);
                        }}
                    >
                        {i18next.t('Close')}
                    </button>
                    <div className='headline-and-text'>
                        <h1>
                            {navigator.onLine
                                ? 'Error'
                                : i18next.t('iFrameOfflineHeadline') ===
                                    'iFrameOfflineHeadline'
                                  ? 'You are offline.'
                                  : i18next.t('iFrameOfflineHeadline')}
                        </h1>
                        <p>
                            {i18next.t('iFrameOfflineText') ===
                            'iFrameOfflineText'
                                ? 'Please check your WIFI settings.'
                                : i18next.t('iFrameOfflineText')}
                        </p>
                    </div>
                    {apk && (
                        <div className='pw'>
                            <input
                                inputMode='none'
                                value={pw}
                                type={showPW ? 'text' : 'password'}
                                onChange={e => setPW(e.target.value)}
                            />
                            <div
                                className='show'
                                onClick={() => setShowPW(!showPW)}
                            >
                                {
                                    <svg>
                                        {showPW ? (
                                            <use xlinkHref='#eye-show' />
                                        ) : (
                                            <use xlinkHref='#eye-hide' />
                                        )}
                                    </svg>
                                }
                            </div>
                            <KeyboardWrapper
                                onChange={i => setPW(i)}
                                onEnter={() => {
                                    const cookie = getCookie('setup');
                                    console.log(cookie, pw);
                                    if (
                                        pw &&
                                        (pw === cookie.userPassword ||
                                            pw ===
                                                `${
                                                    cookie.brand || 'setup'
                                                }@mpa`)
                                    ) {
                                        const newSearch = new URLSearchParams(
                                            window.location.search
                                        );
                                        newSearch.set('locked', 'false');
                                        window.history.replaceState(
                                            null,
                                            '',
                                            '?' + newSearch.toString()
                                        );
                                    }
                                }}
                                enterString={i18next.t('Enter')}
                                langCode={'en-US'}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
