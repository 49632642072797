import React from 'react';

interface VideoPlayerProps {
    video?: string;
}

function VideoPlayer({ video }: VideoPlayerProps): React.ReactElement {
    return (
        <div>
            <video autoPlay loop muted playsInline>
                {video && <source src={video} type='video/mp4' />}
            </video>
        </div>
    );
}

export default VideoPlayer;
